.wcLogo {
  height: 40px;
  width: 32px;
}

.navigation {
  background-color: #2a2337;
}

.navLink {
  cursor: pointer;
}
