@import 'bootstrap/dist/css/bootstrap.min.css';

body {
  background-color: #3A2125;
  color: white;
}

.heroContainer {
  padding-top: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

a {
  color: #fac147 !important;
  font-size: 0.9em;
}

a.nav-link.active {
  background-color: #2a3d27 !important;
}

a.nav-link {
  padding-left: 8px !important;
  display: flex;
}

a.nav-link img {
  padding-left: 4px;
}

::selection {
  color: white !important;
  background-color: #2a3d27 !important;
}